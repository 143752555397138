var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-box black_bg"},[_c('div',{staticClass:"top"},[_c('img',{attrs:{"src":_vm.avatarSrc,"alt":"用户头像"}})]),_c('div',{staticClass:"form"},[_c('div',{staticClass:"international_code"},[_c('van-field',{staticClass:"white_color black_bg background",attrs:{"readonly":"","clickable":"","is-link":"","name":"picker","value":_vm.value,"placeholder":"点击选择国家"},on:{"click":function($event){_vm.showPicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{staticClass:"white_color",attrs:{"show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1)],1),_c('div',{staticClass:"phone"},[_c('div',[_vm._v(" +"),_c('span',{staticClass:"phone_number"},[_vm._v(_vm._s(_vm.internationalCode))])]),_c('van-field',{staticClass:"black_bg white_color background",attrs:{"name":"账号","placeholder":"请输入手机号码","rules":[
          { required: true, message: '请填写账号' },
          {
            pattern: /^[1][0-9]{10}$/,
            message: '请输入正确的11位手机号',
          } ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('div',{staticClass:"password"},[_c('div',[_c('img',{attrs:{"src":_vm.isPassword != 'password' ? _vm.openEye : _vm.closeEye},on:{"click":function($event){_vm.isPassword == 'password'
              ? (_vm.isPassword = '')
              : (_vm.isPassword = 'password')}}})]),_c('van-field',{staticClass:"black_bg white_color background",attrs:{"type":_vm.isPassword,"name":"密码","autocomplete":"”off”","placeholder":"请输入密码","rules":[
          { required: true, message: '请填写密码' },
          { pattern: /^\w{6,}$/, message: '密码不少于6位' } ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('span',[_c('router-link',{staticClass:"white_color background",staticStyle:{"font-size":"15px"},attrs:{"to":{ path: '/forget' }}},[_vm._v("忘记密码")])],1)],1),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"type":"primary","size":"large","native-type":"submit"},on:{"click":_vm.login}},[_vm._v("登录")])],1),_c('div',{staticClass:"bottom"},[_c('span',[(!_vm.isCode)?_c('router-link',{attrs:{"to":{ path: '/emailLogin' }}},[_vm._v("短信验证码或邮箱登录")]):_vm._e()],1),_c('span',{on:{"click":_vm.register}},[_vm._v("立即注册 ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }