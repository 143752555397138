<!-- 用户登录页面 -->
<template>
  <div class="login-box black_bg">
    <!--<van-nav-bar title="" left-text="" left-arrow @click-left="onClickLeft" />-->
    <div class="top">
      <img :src="avatarSrc" alt="用户头像" />
    </div>
    <div class="form">
      <div class="international_code">
        <van-field
          readonly
          clickable
          is-link
          name="picker"
          :value="value"
          placeholder="点击选择国家"
          @click="showPicker = true"
          class="white_color black_bg background"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
            class="white_color"
          />
        </van-popup>
      </div>
      <div class="phone">
        <div>
          +<span class="phone_number">{{ internationalCode }}</span>
        </div>

        <van-field
          v-model="username"
          name="账号"
          placeholder="请输入手机号码"
          class="black_bg white_color background"
          :rules="[
            { required: true, message: '请填写账号' },
            {
              pattern: /^[1][0-9]{10}$/,
              message: '请输入正确的11位手机号',
            },
          ]"
        />
      </div>
      <div class="password">
        <div>
          <img
            :src="isPassword != 'password' ? openEye : closeEye"
            @click="
              isPassword == 'password'
                ? (isPassword = '')
                : (isPassword = 'password')
            "
          />
        </div>
        <van-field
          v-model="password"
          :type="isPassword"
          name="密码"
          class="black_bg white_color background"
          autocomplete="”off”"
          placeholder="请输入密码"
          :rules="[
            { required: true, message: '请填写密码' },
            { pattern: /^\w{6,}$/, message: '密码不少于6位' },
          ]"
        />
        <span
          ><router-link
            :to="{ path: '/forget' }"
            class="white_color background"
            style="font-size: 15px"
            >忘记密码</router-link
          ></span
        >
      </div>
      <div class="button">
        <van-button
          type="primary"
          size="large"
          @click="login"
          native-type="submit"
          >登录</van-button
        >
      </div>
      <div class="bottom">
        <span
          ><router-link v-if="!isCode" :to="{ path: '/emailLogin' }"
            >短信验证码或邮箱登录</router-link
          ></span
        >
        <span @click="register"
          >立即注册
          <!-- :to="{ path: '/register' }" -->
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import headImg from '@/assets/images/login_logo.png'; //默认头像
import closeEye from '@/assets/images/close_eye.png'; //闭眼
import openEye from '@/assets/images/open_eye.png'; //睁眼

export default {
  data() {
    // let proportionNum = localStorage.getItem("proportionNum");
    return {
      avatarSrc: headImg, //头像
      value: '中国 (大陆)',
      columns: ['中国 (大陆)'],
      showPicker: false,
      internationalCode: '86',
      closeEye: closeEye,
      openEye: openEye,
      passwordType: '',
      username: '',
      password: '',
      isPassword: 'password',
      code: ' ',
      isCode: false,
    };
  },
  mounted() {
    this.isCode = sessionStorage.getItem('regcode');
    if (sessionStorage.getItem('regcode')) {
      this.recommendPhone = sessionStorage.getItem('regcode');
      this.tuiJianMayou = sessionStorage.getItem('regcode');
    }
    if (this.$route.query.code) {
      sessionStorage.setItem('regcode', this.$route.query.code);
      // this.$router.push('/register');
    }
    // if (this.code == '') {
    //   this.$router.push('/register');
    // }
  },
  methods: {
    register() {
      if (this.$route.query.code) {
        this.$router.push('/register?code=' + this.$route.query.code);
      } else {
        this.$router.push('/register');
      }
    },
    onSubmit() {
      //点击登录
      //如果不符合登录条件则不会继续执行
      if (this.username.trim() == '') {
        return;
      }
      if (!this.username.match(/^[1][0-9]{10}$/)) {
        return;
      }
      if (!this.password.match(/^\w{6,}$/)) {
        return;
      }

      // 获取数据
      let users = localStorage.users;
      if (users) {
        users = JSON.parse(users);
        let isLogin = false;
        users.map((item) => {
          if (
            item.username == this.username &&
            item.password == this.password
          ) {
            isLogin = true;
            return;
          }
        });

        if (isLogin) {
          sessionStorage.user = this.username;
          this.$router.push({
            path: '/home',
          });
        } else {
          this.$notify({ type: 'danger', message: '输入的账号或密码有误' });
        }
      } else {
        this.$notify({ type: 'danger', message: '该用户不存在' });
      }
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
    },
    onClickLeft() {
      this.$router.back();
    },

    // 登录
    login() {
      if (!this.username) {
        this.$toast('请输入手机号码');
        return;
      }
      if (!this.password) {
        this.$toast('请输入密码');
        return;
      }
      let param = {
        name: this.username,
        password: this.password,
      };
      this.$api.login(param).then((res) => {
        if (res.code == 0) {
          if (/^1[0-9]{10}$/.test(this.username)) {
            localStorage.setItem('loginType', 1);
          } else {
            localStorage.setItem('loginType', 2);
          }
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('nickName', res.data.nickName);

          localStorage.setItem('proportionNum', res.data.proportionNum);
          this.$router.push('/home');
          // if (this.$route.fullPath.indexOf('redirect')) {
          //   const path = this.$route.fullPath.split('redirect=');
          //   if (path.length > 1) {
          //     this.$router.push(decodeURIComponent(path[1]));
          //   } else {
          //     this.$router.push('/home');
          //   }
          // } else {
          //   this.$router.push('/home');
          // }
          // if (this.$route.query.redirect) {
          //   this.$router.push(this.$route.query.redirect);
          // } else {
          //   this.$router.push('/home');
          // }
        } else if (res.code == 1) {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.login-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background-color: black;

  .top {
    // display: flex;
    // justify-content: center;
    text-align: center;
    margin-top: 20px;
    img {
      width: 105px;
      height: auto;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    margin: 10px 20px;
    font-size: 16px;
    color: #c2c2c2;
    margin-top: 50px;
    .international_code {
      /deep/ .van-field__control {
        color: #fff !important;
        font-weight: bold;
      }
    }
    .van-cell {
      padding: 10px 0;
    }
    .phone {
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid rgba(37, 37, 37, 1);
      padding: 5px 0;
      .phone_number {
        padding: 0 15px 0 0px;
        line-height: 1;
        margin-right: 10px;
        color: #fff;
        border-right: 0.5px solid #ffffff;
      }
    }
    .password {
      display: flex;
      border-bottom: 0.5px solid rgba(37, 37, 37, 1);
      align-items: center;
      padding: 5px 0;
      span {
        display: flex;
        width: 38%;
        align-items: center;
      }
      span::before {
        content: ' ';
        display: flex;
        width: 0.5px;
        height: 19px;
        margin-right: 10px;
        background: #eeeeee;
      }
      img {
        width: 16px;
        height: auto;
        padding-right: 20px;
      }
    }
    .button {
      margin-top: 30px;

      .van-button--primary {
        width: 100%;
        height: 42px;
        color: #fff;
        background: #0754d3;
        border: 1px solid #0754d3;
      }
    }
    /deep/.van-field__control {
      color: #fff;
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      padding: 0 10px;
      font-size: 14px;
      color: #cecece;
      a {
        color: #cecece;
      }
    }
  }
}
/deep/.background {
  background-color: black;
}
</style>
